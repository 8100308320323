import React from 'react'

export default function IconError({color, width}) {
    return (
        <svg viewBox="0 0 12 12" width={width} height={width}>
            <path fill={color} d="M6,0.2c3.2,0,5.8,2.6,5.8,5.8S9.2,11.8,6,11.8S0.2,9.2,0.2,6S2.8,0.2,6,0.2z M6,1.4
                C3.5,1.4,1.4,3.5,1.4,6s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S8.5,1.4,6,1.4z M8,4c0.2,0.2,0.2,0.6,0,0.8l0,0L6.8,6L8,7.1
                C8.2,7.3,8.2,7.7,8,8C7.7,8.2,7.4,8.2,7.1,8l0,0L6,6.8L4.9,8C4.6,8.2,4.3,8.2,4,8C3.8,7.8,3.8,7.4,4,7.2l0,0L5.2,6L4,4.9
                C3.8,4.7,3.8,4.3,4,4c0.2-0.2,0.6-0.2,0.8,0l0,0L6,5.2L7.1,4C7.4,3.8,7.7,3.8,8,4z"/>
        </svg>
    )
}

IconError.defaultProps = {
    color: '#FC5959',
    width: 12,
};
