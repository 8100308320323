import React from 'react'
import IconError from 'src/components/icons/Error'

export default function Error({children}) {
    return (
        <div className="text-error text-xs flex flex-start items-center">
            {children &&
                <IconError />
            }
            <span className="pl-1">{children}</span>
        </div>
    )
}
