import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import VisitorLayout from 'src/components/layout/VisitorLayout'
import Heading from 'src/components/typography/Heading'
import Paragraph from 'src/components/typography/Paragraph'
import { LightButton, OutlinedButton } from 'src/components/buttons/Primary';
import heroRegisterStudent from 'src/images/register/hero-register-student.jpg'
import { useVisitorApi } from "../../hooks/useApi";
import useLocalizedRoutes from "../../hooks/useLocalizedRoutes";
import Success from "../../components/forms/Success";
import { Context as ProfileContext } from "../../context/ProfileContext";
import Error from '../../components/forms/Error';
import { navigate } from 'gatsby';

export default function ConfirmEmailStudent(props) {
    const {location} = props;
    const [email, setEmail] = useState('');
    const {resendVerification} = useVisitorApi();
    const {state: {profile}} = useContext(ProfileContext);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const {addStudentDetails, registerStudent} = useLocalizedRoutes();
    const {t} = useTranslation();

    async function resendEmailVerification() {
        setLoading(true);
        const redirectUrl = location.origin + addStudentDetails;
        const res = await resendVerification(redirectUrl);
        if (res.status === 200) {
            setEmailSent(true);
            setSuccessMsg(true);
            setTimeout(() => setSuccessMsg(false), 3000);

        } else setError(Object.values(res.errors).toString());
        setLoading(false);
    }

    useEffect(() => {
        if (location.state) {
            setEmail(location.state.values.email);
        }
    }, [location]);

    return (
        <VisitorLayout {...props} hero={heroRegisterStudent} title={t('student.confirmEmail.title')}>
            <div className="px-6 lg:px-0 pt-12 lg:pt-24">
                <Heading type='head-title-wrap'>{t('student.confirmEmail.title')}</Heading>
                <div className="py-10">
                    <Paragraph>{t('student.confirmEmail.body', {email: email})}</Paragraph>
                </div>
                <OutlinedButton onClick={() => resendEmailVerification()} isLoading={loading}>
                    {t('student.confirmEmail.button')}
                </OutlinedButton>
                {error && <Error>{error}</Error>}
                {successMsg && <Success className="">{t('general.verifyEmail', {email: profile.email})}</Success>}
                <br/>
                <LightButton
                    className="pl-0 pt-6 text-sm"
                    onClick={() => navigate(registerStudent)}>
                    {t('student.confirmEmail.back')}
                </LightButton>
            </div>
        </VisitorLayout>
    );
}
